import {AxiosInstance} from 'axios';
/*import {vars} from '../../vars';
import {TOKEN_KEY} from '../../authProvider';*/
import {LegacyOrder} from '../../interfaces';

export class LegacyService {
  private httpClient: AxiosInstance;

  constructor(httpClient: AxiosInstance) {
    this.httpClient = httpClient;
  }

  public async getLegacy(legacyOrderId: string): Promise<LegacyOrder> {
    console.log('legacyOrderId', legacyOrderId);
    /*const url = `${vars.baseApiUrl}/logistics/admin/exchange-rate/?currency_from=${request.currencyFrom}&currency_to=${request.currencyTo}`;

    const {data} = await this.httpClient.get(url, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(TOKEN_KEY)}`,
      },
    });

    return data;*/
    const data = {
      beforeDate: '2025-02-23',
      couponCode: null,
      createdAt: 1737692355000,
      deliveryCountry: {
        code: 'PE',
        code3: 'PER',
        type: 'country',
      },
      extendedWarranty: false,
      id: 'TRPE04180583',
      invoice: {
        accountingData: null,
        detail: {
          coupon: '0.00',
          couponDeduction: '0.00',
          customsDuties: '0.00',
          customsDutiesDeduction: '0.00',
          importationFee: '80.00',
          importationFeeDeduction: '0.00',
          itemTotal: '12.00',
          itemTotalDeduction: '0.00',
          lastMileShipping: '12.06',
          lastMileShippingDeduction: '0.00',
          qempo: '10.41',
          qempoDeduction: '0.00',
          shipping: '0.00',
          shippingDeduction: '0.00',
          subtotal: '12.00',
          subtotalDeduction: '0.00',
          taxes: '0.00',
          taxesDeduction: '0.00',
          total: '114.47',
          warranty: '0.00',
          warrantyDeduction: '0.00',
        },
        hasPaidRefunds: null,
        id: 'IV15193936',
        payment: {
          country: {
            code: 'PE',
            code3: 'PER',
            type: 'country',
          },
          fraudResult: null,
          id: 'F09276587',
          method: 'KASHIO',
          name: 'Lenovo IdeaPad 1 14.0" Laptop, 4GB RAM, 64GB Storage, Cloud Grey | HD Display, Intel Celeron N4020, Intel UHD Graphics 600, Win 11 in S Mode',
          paymentData: {
            shopperRedirectUrl:
              'https://cert-gateway.kashio.com.pe?token=GBGfmudyiXpMEASbLvk4vP&mode=iframe',
          },
          state: 'UNCONFIRMED',
          subtype: 'TRANSFER',
          total: '114.47',
          type: 'payment',
          updatedAt: 1737692355000,
        },
        state: 'PENDING',
        type: 'invoice',
      },
      isDiy: false,
      items: [
        {
          amount: 1,
          cancellations: [],
          cancelledCount: 0,
          commission: '80.00',
          customsDuties: '0.00',
          detail: '\nCapacity: 64GB Storage',
          hasCancellations: false,
          image:
            'https://res.cloudinary.com/qempo/image/upload/f_auto,q_auto/product-image/hidysxqbv1atrd5cv8yv.jpg',
          isShippingAutomatic: true,
          itemCategoryId: 1,
          name: 'Lenovo IdeaPad 1 14.0" Laptop, 4GB RAM, 64GB Storage, Cloud Grey | HD Display, Intel Celeron N4020, Intel UHD Graphics 600, Win 11 in S Mode',
          number: 1,
          onlineStore: {
            id: 'ST04287850',
            logo: 'https://res.cloudinary.com/qempo/image/upload/v1551903426/static/stores/Amazon.png',
            name: 'Amazon',
            slug: 'amazon',
            type: 'online-store',
            url: 'https://www.amazon.com/',
          },
          originalPackaging: true,
          price: '12.00',
          shipping: '0.00',
          state: 'PENDING',
          taxReserve: '0.00',
          type: 'order-item',
          url: 'https://www.amazon.com/dp/B0BLW25MB7?th=1&psc=1',
          volumeTier: null,
          warranty: '0.00',
          weight: null,
        },
      ],
      onHoldSince: null,
      reservationExpiresAt: null,
      serviceTier: 'FLEXIBLE',
      shipments: [],
      shippingAddress: {
        address1: 'Calle A',
        address2: null,
        admin1: {
          id: 6,
          name: 'Lima',
          type: 'admin1',
        },
        admin2: {
          id: 127,
          name: 'Lima',
          type: 'admin2',
        },
        admin3: {
          id: 1286,
          name: 'San Miguel',
          type: 'admin3',
        },
        country: {
          code: 'PE',
          code3: 'PER',
          type: 'country',
        },
        firstName: 'Gi Wah',
        idNumber: '71237616',
        lastName: 'D\u00e1valos',
        phone: '4523792',
        type: 'shipping-address',
        zipCode: '15088 ',
      },
      shippingIdentity: null,
      shippingIdentityId: null,
      state: 'CREATED',
      travelerReview: null,
      type: 'order',
      underReviewSince: null,
    };

    return new Promise((res) => setTimeout(() => res(data), 1500));
  }
}
