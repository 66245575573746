import React, {useState} from 'react';
import {IResourceComponentsProps} from '@refinedev/core';
import {
  Alert,
  Button,
  Card,
  Col,
  Descriptions,
  Divider,
  Form,
  FormProps,
  Image,
  Row,
  Space,
  Spin,
  Table,
  Tag,
  Typography,
} from 'antd';
import {LegacyService} from '../../service';
import {axiosInstance} from '@refinedev/simple-rest';
import {AxiosInstance} from 'axios';
import Search from 'antd/es/input/Search';
import {Emoji, OrderState, ShowAddress} from '../../../../layout/components';
import {
  ICountry,
  IShippingAddress,
  LegacyOrder,
  LegacyOrderItem,
} from '../../../../interfaces';
import {OrderBeforeDate} from '../../../order/components';
import {OrderServiceType} from '../../../../layout/components/order-service-type';
import {dateFmt} from '../../../../utils/date';
import {InvoiceState} from '../../../../layout/components/invoice-state';
import {NumberField} from '@refinedev/antd';
import {DollarOutlined} from '@ant-design/icons';
import {getLegacyCategory} from '../../../../utils/category';

const legacyService = new LegacyService(axiosInstance as AxiosInstance);

export const LegacyOrderShow: React.FC<IResourceComponentsProps> = () => {
  const [order, setOrder] = useState<LegacyOrder>();
  const [isLoading, setIsLoading] = useState(false);

  const [form] = Form.useForm();

  const onFinish: FormProps<string>['onFinish'] = ({legacyOrderId}: any) => {
    setOrder(undefined);
    setIsLoading(true);

    legacyService
      .getLegacy(legacyOrderId)
      .then((order) => setOrder(order as any))
      .finally(() => setIsLoading(false));
  };

  return (
    <Space
      direction="vertical"
      size="middle"
      style={{
        width: '100%',
      }}
    >
      <Row gutter={[16, 16]} justify="center">
        <Col xs={24} md={12} lg={12} xl={12}>
          <Card>
            <Form
              form={form}
              id="legacyOrderForm"
              name="basic"
              layout="vertical"
              labelCol={{span: 8}}
              wrapperCol={{span: 16}}
              onFinish={onFinish}
              autoComplete="off"
            >
              <Form.Item<string>
                name="legacyOrderId"
                label="Legacy Order ID"
                wrapperCol={{offset: 0}}
                style={{width: '100%', margin: 0}}
                rules={[
                  {required: true, message: 'Please input your order id!'},
                  {
                    pattern: new RegExp(/^TRPE[0-9]+$/),
                    message: 'Incorrect format!',
                  },
                ]}
              >
                <Search
                  placeholder="TRPEXXXXXXXX"
                  onSearch={() => form.submit()}
                  enterButton
                  disabled={isLoading}
                />
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
      {isLoading && (
        <Row gutter={[16, 16]}>
          <Col xs={24}>
            <div style={{padding: '72px 24px', textAlign: 'center'}}>
              <Spin />
            </div>
          </Col>
        </Row>
      )}
      {order && (
        <>
          <Row gutter={[16, 16]}>
            <Col xs={24} md={24} lg={24} xl={8}>
              <Space
                direction="vertical"
                size="middle"
                style={{
                  width: '100%',
                }}
              >
                <Card>
                  <Space
                    direction="vertical"
                    size="small"
                    style={{
                      width: '100%',
                      height: '100%',
                    }}
                  >
                    <Typography.Title
                      level={3}
                      style={{
                        textAlign: 'center',
                        margin: '0 -24px 16px',
                        paddingRight: '24px',
                        paddingLeft: '24px',
                      }}
                    >
                      {order?.id}
                    </Typography.Title>
                    <Divider style={{margin: '0 0 8px 0'}} />
                    <Typography.Text>
                      <strong>State:</strong> <OrderState state={order.state} />
                    </Typography.Text>
                    <Typography.Text>
                      <strong>Before date:</strong>{' '}
                      <OrderBeforeDate beforeDate={order.beforeDate} />
                    </Typography.Text>
                    <Typography.Text>
                      <strong>Creation date:</strong>{' '}
                      {dateFmt(new Date(order.createdAt))}
                    </Typography.Text>
                    <Typography.Text>
                      <strong>Type:</strong>{' '}
                      <OrderServiceType
                        serviceType={order.isDiy ? 'LITE' : 'FULL'}
                      />
                    </Typography.Text>
                    {order.state === 'CREATED' &&
                      order.invoice.state === 'PENDING' && (
                        <>
                          <br />
                          <Button
                            type="primary"
                            size="large"
                            icon={<DollarOutlined />}
                            block
                          >
                            Pay order
                          </Button>
                        </>
                      )}
                  </Space>
                </Card>
                <Card
                  title={
                    <div
                      style={{display: 'flex', justifyContent: 'space-between'}}
                    >
                      <span>Invoice: {order.invoice.id}</span>
                    </div>
                  }
                >
                  <Space
                    direction="vertical"
                    size="small"
                    style={{
                      width: '100%',
                      height: '100%',
                    }}
                  >
                    <Typography.Text>
                      <strong>State:</strong>{' '}
                      <InvoiceState state={order.invoice.state} />
                    </Typography.Text>
                    <Descriptions
                      size="small"
                      title={
                        <Typography.Text>
                          <strong>Summary:</strong>
                        </Typography.Text>
                      }
                      bordered
                      column={1}
                    >
                      {order.invoice &&
                        Object.keys(order.invoice.detail)
                          .filter(
                            (k) =>
                              k !== 'total' &&
                              k !== 'subtotal' &&
                              k !== 'subtotalDeduction'
                          )
                          .filter(
                            (k) =>
                              parseFloat(
                                (
                                  order.invoice.detail as Record<string, string>
                                )[k]
                              ) !== 0
                          )
                          .map((k: string) => (
                            <Descriptions.Item
                              key={k}
                              label={k}
                              labelStyle={{
                                textAlign: 'right',
                                wordBreak: 'break-word',
                              }}
                              contentStyle={{
                                textAlign: 'right',
                                wordBreak: 'inherit',
                                whiteSpace: 'nowrap',
                              }}
                            >
                              {order.invoice && (
                                <NumberField
                                  value={
                                    (
                                      order.invoice.detail as Record<
                                        string,
                                        string
                                      >
                                    )[k]
                                  }
                                  options={{
                                    style: 'currency',
                                    currency: 'USD',
                                  }}
                                  style={{
                                    color:
                                      parseFloat(
                                        (
                                          order.invoice.detail as Record<
                                            string,
                                            string
                                          >
                                        )[k]
                                      ) < 0
                                        ? 'red'
                                        : 'inherit',
                                  }}
                                />
                              )}
                            </Descriptions.Item>
                          ))}
                      {order.invoice && (
                        <Descriptions.Item
                          label="Total"
                          labelStyle={{
                            textAlign: 'right',
                            fontWeight: 700,
                          }}
                          contentStyle={{textAlign: 'right'}}
                        >
                          <strong>
                            <NumberField
                              value={order.invoice.detail.total}
                              options={{
                                style: 'currency',
                                currency: 'USD',
                              }}
                              style={{
                                wordBreak: 'inherit',
                                whiteSpace: 'nowrap',
                              }}
                            />
                          </strong>
                        </Descriptions.Item>
                      )}
                    </Descriptions>
                  </Space>
                </Card>
                <Card
                  title={
                    <div
                      style={{display: 'flex', justifyContent: 'space-between'}}
                    >
                      <span>Payment: {order.invoice.payment.id}</span>
                    </div>
                  }
                >
                  <Space
                    direction="vertical"
                    size="small"
                    style={{
                      width: '100%',
                      height: '100%',
                    }}
                  >
                    <Typography.Text>
                      <strong>State:</strong>{' '}
                      <OrderState state={order.invoice.payment.state} />
                    </Typography.Text>
                    <Typography.Text>
                      <strong>Method:</strong>{' '}
                      <Tag>{order.invoice.payment.method}</Tag>
                    </Typography.Text>
                    <Typography.Text>
                      <strong>Subtype:</strong>{' '}
                      <Tag>{order.invoice.payment.subtype}</Tag>
                    </Typography.Text>
                  </Space>
                </Card>
              </Space>
            </Col>
            <Col xs={24} md={24} lg={24} xl={16}>
              <Space
                direction="vertical"
                size="middle"
                style={{
                  width: '100%',
                }}
              >
                <Card
                  title={
                    <Space
                      direction="horizontal"
                      style={{display: 'flex', justifyContent: 'space-between'}}
                    >
                      <Typography.Text>
                        <Emoji emoji="👤" style={{marginRight: 8}} />
                        Purchaser
                      </Typography.Text>
                    </Space>
                  }
                >
                  <Row gutter={[16, 16]}>
                    <Col span={24}>
                      <Descriptions column={1}>
                        {'firstName' in order.shippingAddress && (
                          <Descriptions.Item label="First name">
                            {order.shippingAddress.firstName}
                          </Descriptions.Item>
                        )}
                        {'lastName' in order.shippingAddress && (
                          <Descriptions.Item label="Last name">
                            {order.shippingAddress.lastName}
                          </Descriptions.Item>
                        )}
                        {'idNumber' in order.shippingAddress && (
                          <Descriptions.Item label="DNI">
                            {order.shippingAddress.idNumber}
                          </Descriptions.Item>
                        )}
                        {'phone' in order.shippingAddress && (
                          <Descriptions.Item label="Phone">
                            {order.shippingAddress.phone}
                          </Descriptions.Item>
                        )}
                      </Descriptions>
                    </Col>
                  </Row>
                </Card>
                <Card
                  style={{minHeight: '100%'}}
                  title={
                    <div
                      style={{display: 'flex', justifyContent: 'space-between'}}
                    >
                      <span>
                        <Emoji emoji="📍" /> Shipping Address
                      </span>
                    </div>
                  }
                >
                  <ShowAddress
                    address={
                      {
                        firstName: order.shippingAddress.firstName,
                        lastName: order.shippingAddress.lastName,
                        idNumber: order.shippingAddress.idNumber,
                        phone: order.shippingAddress.phone,
                        address1: order.shippingAddress.address1,
                        address2: order.shippingAddress.address2 || undefined,
                        country: order.shippingAddress.country as ICountry,
                        admin1: order.shippingAddress.admin1,
                        admin2: order.shippingAddress.admin2,
                        admin3: order.shippingAddress.admin3,
                        zipCode: order.shippingAddress.zipCode,
                      } as IShippingAddress
                    }
                  />
                </Card>
                <Card
                  style={{minHeight: '100%'}}
                  title={
                    <div
                      style={{display: 'flex', justifyContent: 'space-between'}}
                    >
                      <span>Order Items</span>
                    </div>
                  }
                >
                  <Table
                    pagination={false}
                    dataSource={order.items}
                    rowKey="id"
                    size="small"
                    scroll={{x: true}}
                  >
                    <Table.Column<LegacyOrderItem>
                      key="image"
                      title="Image"
                      align="center"
                      render={(record: LegacyOrderItem) => (
                        <Image
                          width={128}
                          height={128}
                          src={record.image}
                          draggable={false}
                          style={{objectFit: 'scale-down'}}
                        />
                      )}
                    />

                    <Table.Column<LegacyOrderItem>
                      key="category"
                      title="Category"
                      render={(record: LegacyOrderItem) => (
                        <Tag>
                          {getLegacyCategory(record.itemCategoryId)?.name}
                        </Tag>
                      )}
                    />

                    <Table.Column<LegacyOrderItem>
                      key="name"
                      title="Name"
                      render={(record: LegacyOrderItem) => (
                        <Space
                          direction="vertical"
                          size="middle"
                          style={{width: '100%'}}
                        >
                          <Typography.Link
                            href={record.url}
                            style={{display: 'block', minWidth: 250}}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {record.name}
                          </Typography.Link>
                          {record.detail && (
                            <Alert
                              message={record.detail}
                              type="info"
                              showIcon
                              icon={
                                <Emoji emoji="ℹ️" style={{marginRight: 8}} />
                              }
                            />
                          )}
                        </Space>
                      )}
                    />

                    <Table.Column<LegacyOrderItem>
                      key="price"
                      title="Price"
                      render={(record: LegacyOrderItem) => (
                        <NumberField
                          value={record.price}
                          options={{
                            style: 'currency',
                            currency: 'USD',
                          }}
                          style={{
                            whiteSpace: 'nowrap',
                            display: 'block',
                          }}
                        />
                      )}
                    />

                    <Table.Column<LegacyOrderItem>
                      key="weight"
                      title="Weight"
                      render={(record: LegacyOrderItem) => (
                        <Typography.Text
                          style={{wordBreak: 'inherit', whiteSpace: 'nowrap'}}
                        >
                          {record.weight} Kg
                        </Typography.Text>
                      )}
                    />

                    <Table.Column<LegacyOrderItem>
                      key="amount"
                      title="Amount"
                      render={(record: LegacyOrderItem) => (
                        <Tag>{record.amount}</Tag>
                      )}
                    />
                  </Table>
                </Card>
              </Space>
            </Col>
          </Row>
        </>
      )}
    </Space>
  );
};
